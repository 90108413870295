<template>
  <div v-frag>
    <v-list
      v-if="!nav"
      class="py-0 fill-height d-flex"
      color="transparent"
      flat
    >
      <v-list-item @click.stop="goInvoiceCreate()">
        <v-list-item-content>
          <v-list-item-title>
            <icon-buy-button class="mr-2" size="1.7em" />
            <span v-text="$t(`create${!isMobile ? '.invoice' : ''}`)" />
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-menu
        v-if="!nav && items.length > 1"
        :close-delay="200"
        bottom
        left
        offset-overflow
        offset-y
        open-on-hover
        rounded="t-0 b-lg"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-list-item link class="pl-0" v-bind="attrs" v-on="on">
            <v-list-item-icon class="pr-0 mr-0">
              <v-icon>mdi-menu-down</v-icon>
            </v-list-item-icon>
          </v-list-item>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            @click="goInvoiceCreate(item.to)"
          >
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-list>
    <v-btn
      v-else
      :class="sClass"
      :color="nav ? 'primary' : undefined"
      :icon="nav"
      :text="!nav"
      @click.stop="goInvoiceCreate()"
    >
      <icon-add v-if="nav" size="1em" />
      <template v-else>
        <icon-buy-button class="mr-2" size="1.7em" />
        <span class="d-none d-lg-inline">{{ $t("create.invoice") }}</span>
      </template>
    </v-btn>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { RouteNamedMap } from "@/types/typed-router";
import { EventBus } from "@/services/event-bus";
import { InvoiceModule } from "@/store/invoice";
import { canRouteAccess } from "@/services/moduleAccess";
import { filter } from "lodash";
import type { MenuItem } from "@/config/mainMenu";
import { getRoute, routeAccess } from "@/plugins/helpers";
import { AuthModule } from "@/store/auth";

@Component
export default class InvoiceBtnCreate extends Vue {
  @Prop(String) readonly route!: keyof RouteNamedMap;
  @Prop(Boolean) readonly nav!: boolean;

  arItemsList: Array<keyof RouteNamedMap> = [];

  get sRouteName(): keyof RouteNamedMap | undefined {
    return this.route ?? this.$route?.name;
  }

  get sCreateRouteName(): keyof RouteNamedMap {
    let sRoute: keyof RouteNamedMap = "invoices.create";

    if (this.sRouteName) {
      switch (true) {
        case /^(invoices\.receipt\.)(.*)$/i.test(this.sRouteName):
          sRoute = "invoices.receipt.create";
          break;

        case /^(invoices\.debt\.)(.*)$/i.test(this.sRouteName):
          sRoute = "invoices.debt.create";
          break;
      }
    }

    return sRoute;
  }

  get items(): MenuItem[] {
    const arMenuItems: MenuItem[] = [
      { title: "sales", to: "invoices.create" },
      { title: "debt.collection", to: "invoices.debt.create" },
      { title: "invoices.paid", to: "invoices.receipt.create" },
    ];

    return filter(arMenuItems, (obItem: MenuItem) =>
      this.arItemsList.includes(obItem.to as keyof RouteNamedMap)
    );
  }

  get sClass() {
    return { "mr-4": !this.nav };
  }

  get isMobile() {
    return this.$vuetify.breakpoint.xs;
  }

  goInvoiceCreate(sValue?: keyof RouteNamedMap) {
    const sCreateRoute = sValue ?? this.sCreateRouteName;
    const sRouteName = sValue ?? this.sRouteName;

    if (this.$route.name === sCreateRoute) {
      EventBus.emit("reload.form");
    } else if (sRouteName) {
      InvoiceModule.setRoutedMovementTypeCode(sRouteName);
      this.$router.push({ name: sCreateRoute });
    }
  }

  getItemList() {
    this.arItemsList = filter(
      ["invoices.create", "invoices.receipt.create", "invoices.debt.create"],
      (sRoute: keyof RouteNamedMap) => {
        const obRoute = getRoute(sRoute, this.$router);
        return routeAccess(obRoute, AuthModule.user) && canRouteAccess(sRoute);
      }
    );
  }

  created() {
    EventBus.on("app.company.selected", this.getItemList);
  }

  beforeDestroy() {
    EventBus.off("app.company.selected", this.getItemList);
  }

  mounted() {
    this.getItemList();
  }
}
</script>
