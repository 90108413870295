<template>
  <v-data-table
    :headers="headers"
    :items="arItems"
    :options="pagination"
    :server-items-length="total"
    :loading="loading"
    :class="{ 'dt-listing__loading': loading }"
    hide-default-footer
    calculate-widths
    dense
    class="dt-listing"
    item-class="css"
    v-bind="$attrs"
    @update:page="onUpdatePage"
    @update:sort-by="onUpdateSortBy"
    @update:sort-desc="onUpdateSortDesc"
    @update:options="onUpdateOptions"
  >
    <template #[`item.actions`]="{ item }">
      <action-buttons
        :path="btnActionPath"
        :item-id="$_.get(item, btnActionItemKeyId)"
        :hide-view="hideBtnActionView"
        :hide-update="hideBtnActionUpdate"
        :hide-delete="hideBtnActionDelete"
        @delete="deleteItem"
      ></action-buttons>
    </template>

    <!-- Pass on all named slots -->
    <template v-for="(_, name) in $slots" v-slot:[name]>
      <slot :name="name" />
    </template>

    <!-- Pass on all scoped slots -->
    <template v-for="(_, name) in $scopedSlots" v-slot:[name]="slotProps">
      <slot :name="name" v-bind="slotProps" />
    </template>

    <template #no-data>
      <div class="text-center">
        <h4>{{ $t("datatable.nodata.loaded") }}</h4>
        <!-- <v-btn color="primary" @click="index">Reintentar </v-btn> -->
      </div>
    </template>

    <template #footer>
      <footer-table
        :value="pagination.page"
        :total="total"
        :lastPage="lastPage"
        @input="onUpdatePage"
      />
    </template>
  </v-data-table>
</template>

<script lang="ts">
/**
 * Extends `v-data-table` with action buttons and pagination footer
 * @displayName DataTable
 */
import { Component, Prop, PropSync, Vue } from "vue-property-decorator";
import FooterTable from "./Footer.vue";
import ActionButtons from "./ActionButtons.vue";
import type { DataOptions, DataTableHeader } from "vuetify";

@Component({
  components: {
    FooterTable,
    ActionButtons,
  },
})
export default class DataTable extends Vue {
  /**
   * An array of objects that each describe a header column
   * @type {DataTableHeader[]}
   * @default []
   * @example ```json
   * {
   *   text: string,
   *   value: string,
   *   align?: 'start' | 'center' | 'end',
   *   sortable?: boolean,
   *   filterable?: boolean,
   *   groupable?: boolean,
   *   divider?: boolean,
   *   class?: string | string[],
   *   cellClass?: string | string[],
   *   width?: string | number,
   *   filter?: (value: any, search: string, item: any) => boolean,
   *   sort?: (a: any, b: any) => number
   * }
   * ```
   */
  @Prop(Array) readonly headers!: DataTableHeader[];
  /**
   * The array of items to display
   * @type {array}
   * @default []
   */
  @Prop(Array) readonly items!: Record<string, any>[];
  /**
   * Number total of elements on query
   * @type {number}
   * @default 0
   */
  @Prop({ type: Number, default: 0 }) readonly total!: number;
  /**
   * Last page number
   * @type {number}
   * @default 1
   */
  @Prop({ type: Number, default: 1 }) readonly lastPage!: number;
  /**
   * Display loading state
   * @type {boolean}
   * @default false
   */
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
  /**
   * Pagination options
   * @type {DataOptions}
   * @example ```json
   * {
   *    page: number,
   *    itemsPerPage: number,
   *    sortBy: string[],
   *    sortDesc: boolean[],
   *    groupBy: string[],
   *    groupDesc: boolean[],
   *    multiSort: boolean,
   *    mustSort: boolean
   * }
   * ```
   */
  @PropSync("options", { type: Object, default: {} }) pagination!: DataOptions;

  // ActionButtons properties
  @Prop(String) readonly btnActionPath!: string;
  @Prop({ type: String, default: "id" }) readonly btnActionItemKeyId!: string;
  @Prop(Boolean) readonly hideBtnActionView!: boolean;
  @Prop(Boolean) readonly hideBtnActionUpdate!: boolean;
  @Prop(Boolean) readonly hideBtnActionDelete!: boolean;

  get arItems() {
    return this.items;
    // return this.$_.map(this.items, (item) => {
    //   this.$_.set(item, "css", "tw-bg-white tw-border-0 tw-rounded-lg");
    //   return item;
    // });
  }

  deleteItem(sItemId: number | string) {
    this.$emit("delete", sItemId);
  }

  onUpdatePage(iPage: number) {
    this.$emit("update:page", iPage);
  }

  onUpdateSortBy(sValue: string | string[]) {
    this.$emit("update:sort-by", this.$_.castArray(sValue));
  }

  onUpdateSortDesc(sValue: boolean) {
    this.$emit("update:sort-desc", this.$_.castArray(sValue));
  }

  onUpdateOptions(sValue: DataOptions) {
    this.$emit("update:options", sValue);
  }

  mounted() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return;
    }

    const arElements = document.querySelectorAll(
      ".v-data-table--mobile table > colgroup"
    );
    if (arElements.length) {
      arElements.forEach((el) => el.remove());
    }
  }
}
</script>
