<script lang="ts">
import { Vue, Component, Prop } from "vue-property-decorator";
import type { BranchData, FirmData } from "@planetadeleste/vue-mc-gw";
import { Branch } from "@planetadeleste/vue-mc-gw";
import {has} from "lodash";

@Component({})
export default class BranchItemPreview extends Vue {
  @Prop(Object) readonly item!: BranchData | Branch;
  @Prop(Boolean) readonly hideDefaultIcon!: boolean;
  @Prop(Boolean) readonly hideIcon!: boolean;
  @Prop(Boolean) readonly hideSubtitle!: boolean;
  @Prop(Boolean) readonly dense!: boolean;
  @Prop({ type: [String, Number], default: "2em" }) readonly iconSize!: string;

  get obBranch(): Branch {
    return this.item instanceof Branch ? this.item : new Branch(this.item);
  }

  get obFirm(): FirmData {
    return this.obBranch.get("firm", {});
  }

  get sInfoColor() {
    return this.obBranch.id
      ? this.obBranch.is_default
        ? "green"
        : "gray"
      : "blue";
  }

  get obClass() {
    return {
      "px-0": this.dense,
    };
  }

  get hasAction(): boolean {
    return has(this.$slots, "action");
  }
}
</script>

<template>
  <v-list-item
    :dense="dense"
    :color="sInfoColor"
    :class="obClass"
    v-bind="$attrs"
    v-on="$listeners"
    class="my-0 py-0 min-h-0 max-w-full"
  >
    <v-list-item-icon v-if="!hideIcon">
      <icon-home :size="iconSize" />
    </v-list-item-icon>
    <v-list-item-content>
      <template v-if="obBranch.id">
        <v-list-item-title v-text="`${obFirm.name} (${obFirm.code})`" />
        <v-list-item-subtitle
          v-if="
            !hideSubtitle && (obFirm.address || obFirm.phone || obFirm.email)
          "
          class="text-caption text-truncate"
        >
          {{ obFirm.address }}
          {{ obFirm.phone }}
          {{ obFirm.email }}
        </v-list-item-subtitle>
      </template>
      <v-list-item-title v-text="$t('create.company.branch')" v-else />
    </v-list-item-content>
    <v-list-item-action v-if="hasAction || (obBranch.is_default && !hideDefaultIcon)">
      <icon-check-circle v-if="obBranch.is_default && !hideDefaultIcon" :size="iconSize" />
      <slot name="action" />
    </v-list-item-action>
  </v-list-item>
</template>
